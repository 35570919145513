import React, { useMemo, useState } from "react";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import SelectField from 'components/Form/SelectField/SelectField';
import DragAndDropContainer from 'modules/WebBuilder-V2/shared/DragAndDropContainer';
import { SORT_ITEMS_IN_CONTAINER_ACTION } from 'modules/WebBuilder-V2/interfaces/blockTypes';
import ToggleCheckbox from 'modules/WebBuilder-V2/shared/ToggleCheckbox';
import { ReactComponent as DraghIcon } from "assets/images/hotelAccommodation/drag.svg";
import { ReactComponent as EditIcon } from "assets/images/webBuilder/edit-hero.svg";
import { ReactComponent as DeleteIcon } from "assets/images/webBuilder/close-nav.svg";
import { useHistory, useParams } from 'react-router-dom';
import AddEditMenuModal from './AddEditMenuModal';
import { generatePageInitialContent } from 'modules/WebBuilder-V2/helpers/generateContent';
import Locale from 'translations';
import generateUniqueID from 'helpers/generateUniqueID';





function Pages() {
	const { commons } = Locale;
	const { webBuilder } = Locale;
	const dispatch = useWebBuilderDispatch();
	const { mainMenu, style, selectedLanguage, languages } = useWebBuilderState();
	const history = useHistory();
	const { bundle } = useParams();
	const [pageDataModal, setPageDataModal] = useState({ isOpen: false, selectedItem: null });
	const pageContent = useMemo(() => {
		if (style?.theme) {
			return generatePageInitialContent(style?.theme);
		}
	}, [style?.theme, mainMenu])

	const [errors, setErrors] = useState(null);

	function togglePageDataModal(selectedItem, type) {
		setPageDataModal({ isOpen: !pageDataModal.isOpen, selectedItem, type });
		setErrors(null)
	}

	const menuOptions = [
		{ id: "main-menu", name: webBuilder?.mainMenu },
	]

	function handleEditChanges(value, keyName, language) {
		let selectedMenuClone = { ...pageDataModal.selectedItem }
		if (language) {
			selectedMenuClone[keyName] = { ...selectedMenuClone[keyName], [language]: value }
		} else {
			selectedMenuClone[keyName] = value
		}

		setPageDataModal({
			...pageDataModal,
			selectedItem: selectedMenuClone
		})
		if (keyName === "url") {
			const isItemExist = mainMenu.find(item =>
				item?.url?.toLowerCase() === value?.toLowerCase() ||
				item?.subNavs?.find(subItem => subItem?.url?.toLowerCase() === value?.toLowerCase())
			);

			if (isItemExist) {
				setErrors({ url: "Menu item duplicated" })
			} else {
				setErrors({})
			}
		}
	}

	function sortMenuItems({ dragIndex, hoverIndex, parentIndex }) {
		dispatch({
			type: "sortMenuItems",
			payload: {
				dragIndex,
				hoverIndex,
				parentIndex
			},
		});
	};

	function updateMenu({ name, value, index, subIndex }) {
		dispatch({
			type: "updateMenuItems",
			payload: {
				name,
				value,
				index,
				subIndex
			},
		});
	};

	function addItemsToMenu(item, content) {
		pageDataModal.selectedItem.index > -1 ?
			dispatch({
				type: "editMenuItem",
				payload: {
					item,
					itemIndex: pageDataModal.selectedItem.index,
					itemSubIndex: pageDataModal.selectedItem.subIndex,
					newUrl: pageDataModal.selectedItem.url?.toLowerCase(),
					content,
				},
			})
			:
			dispatch({
				type: "addMenuItem",
				payload: {
					uuid: generateUniqueID(),
					item,
					newUrl: pageDataModal.selectedItem.url?.toLowerCase(),
					content,
					languages
				},
			})
		togglePageDataModal();
		history.push({
			pathname: `/${bundle}/web-builder-v2/edit/${pageDataModal.selectedItem.url}`,
			pageTheme: item?.page_theme?.id
		})
	}

	function deleteMenuItem({ index, subIndex, pageId }) {
		dispatch({
			type: "deleteMenuItem",
			payload: {
				index,
				subIndex,
				pageId
			},
		});
		history.push({
			pathname: `/${bundle}/web-builder-v2/edit`,
		})
	}

	return (
		<div className="sidebar-pages">
			<div className="content-header">
				<h4>{commons.pages}</h4>
			</div>

			<div className="pages-details">
				<div className="menu-type">
					<SelectField
						id="menu-type"
						name="menu-type"
						options={menuOptions}
						value={menuOptions[0].name}
					/>
				</div>
				<ul className="menu-nav-list">
					<li className="nav-item pointer" onClick={() => history.push(`/${bundle}/web-builder-v2/edit/`)}>
						<span>{webBuilder.home}</span>
					</li>
					{mainMenu?.map((item, index) => {
						return (
							<DragAndDropContainer
								accept={"menuBlocksType"}
								type={"menuBlocksType"}
								id={item.id}
								index={index}
								action={SORT_ITEMS_IN_CONTAINER_ACTION}
								moveItemInContainer={(dragIndex, hoverIndex) =>
									sortMenuItems({ dragIndex, hoverIndex, parentIndex: -1 })
								}
								key={item.id}
							>
								<li>
									<div className="d-flex justify-content-between align-items-center">
										<div className="nav-item">
											<div className="d-flex align-items-center pointer"
												onClick={() => {
													if (!item?.subNavs || item?.subNavs?.length === 0) {
														history.push({
															pathname: `/${bundle}/web-builder-v2/edit/${item.url}`,
															pageTheme: item?.page_theme?.id
														})
													}
												}}
											>
												<DraghIcon className="drag-icon" />
												<span>{item.title?.[selectedLanguage]}</span>
											</div>
											<div className="d-flex gap-10">
												<ToggleCheckbox
													checked={item.visible}
													onChange={(e) => {
														const value = e.target.checked;
														updateMenu({ name: "visible", value, index })
													}}
												/>
												{item?.id !== "about-page" ?
													<button className="btn p-0 shadow-none"
														onClick={() => togglePageDataModal({ ...item, index }, "Edit")}
													>
														<EditIcon />
													</button>
													:
													null
												}
											</div>
										</div>
										{item?.id !== "about-page" ?
											<button className="btn delete-item py-0" onClick={() => deleteMenuItem({ index, pageId: item.pageId })}>
												<DeleteIcon width="10px" />
											</button>
											:
											null
										}
									</div>



									{/********************************* sub nav ******************************************/}
									{item?.subNavs?.length > 0 &&
										<ul className="menu-sub-nav-list">
											{item?.subNavs?.map((subNav, subIndex) => {
												return (
													<DragAndDropContainer
														accept={"subMenuBlocksType"}
														type={"subMenuBlocksType"}
														id={subNav.id}
														index={subIndex}
														action={SORT_ITEMS_IN_CONTAINER_ACTION}
														moveItemInContainer={(dragIndex, hoverIndex) =>
															sortMenuItems({ dragIndex, hoverIndex, parentIndex: index })
														}
														key={subNav.id}
													>
														<li className="d-flex justify-content-between align-items-center">
															<div className="nav-item sub-nav-item">
																<div className="d-flex align-items-center pointer"
																	onClick={() => history.push({
																		pathname: `/${bundle}/web-builder-v2/edit/${subNav.url}`,
																		pageTheme: subNav?.page_theme?.id
																	})}
																>
																	<DraghIcon className="drag-icon" />
																	<span>{subNav?.name[selectedLanguage] || subNav?.title?.[selectedLanguage]}</span>
																</div>
																<div className="d-flex gap-10">
																	<ToggleCheckbox
																		checked={subNav.visible}
																		onChange={(e) => {
																			const value = e.target.checked;
																			updateMenu({ name: "visible", value, index, subIndex })
																		}}
																		disabled={!item.visible}
																	/>
																	<button className="btn p-0 shadow-none"
																		onClick={() => togglePageDataModal({ ...subNav, index, subIndex }, "Edit")}
																		disabled={!item.visible}
																	>
																		<EditIcon />
																	</button>
																</div>
															</div>
															<button className="btn delete-item" onClick={() => deleteMenuItem({ index, subIndex })}>
																<DeleteIcon width="10px" />
															</button>
														</li>
													</DragAndDropContainer>
												)
											})}
										</ul>
									}
								</li>
							</DragAndDropContainer>
						)
					})}
				</ul>
				<button className="primary-button mt-3" onClick={() => togglePageDataModal(null, "Add")}>{commons.addPages}</button>
			</div>


			{/* page data */}
			{pageDataModal.isOpen ?
				<AddEditMenuModal
					isOpen={pageDataModal.isOpen}
					type={pageDataModal.type}
					selectedItem={pageDataModal?.selectedItem}
					togglePageDataModal={togglePageDataModal}
					handleEditChanges={handleEditChanges}
					addItemsToMenu={addItemsToMenu}
					mainMenu={mainMenu}
					pageContent={pageContent}
					errors={errors}
					languages={languages}
					selectedLanguage={selectedLanguage}
				/>
				:
				null
			}
		</div>
	);
}

export default Pages;
