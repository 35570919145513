import SelectField from 'components/Form/SelectField/SelectField';
import DateTimePickerField from "components/Form/DateTimePickerField/DateTimePickerField";
import React, { useState } from 'react';
import validate from "helpers/validate";
import moment from 'moment';
import Locale from 'translations';
import { ReactComponent as DeleteIcon } from "assets/images/umrah-operations/trash-icon.svg";
import { attractionsNamesLookup } from 'services/operationStatement';
// import { useParams, useLocation } from 'react-router-dom';
// import { store } from 'react-notifications-component';
import { getOperationsLookup } from 'modules/UmrahOperations/helper/operationsLookup';
// import { useSBSState } from 'context/global';



export default function OperationStatementAttractions({ OperationStatement, setOperationStatement, isDisabled, operationHasSent, submitProduct }) {
  const attractionsData = OperationStatement.attractions;
  const { operationStatement } = Locale;
  // const { isAuth } = useSBSState();
  // const { reference, id } = useParams();
  // const location = useLocation();
  // const isPublic = location.pathname.includes("public");
  // const statementId = isPublic ? id : reference;
  // const [isFirst, setisFirst] = useState(false);
  const [errors, setErrors] = useState({});
  // const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  // const operation_details = OperationStatement.operation_details;
  const { citiesLookup } = getOperationsLookup(Locale);


  function addNewAttraction() {
    const attractionCLone = [...attractionsData]
    const newAttractionObj = {
      city_id: null,
      attractions_ids: [],
      date_time: null,
      cityAttractionsLookup: [],
    };
    setOperationStatement({ ...OperationStatement, attractions: [...attractionCLone, newAttractionObj] });
  }

  async function handleAttractionsInputs(value, keyName, attractionIndex) {
    let attractionsClone = [...attractionsData];
    let attractionItem = attractionsClone[attractionIndex];
    let cityAttractionsLookup = attractionItem?.cityAttractionsLookup;
    let attractions_ids = keyName === "attractions_ids" ? value : attractionItem?.attractions_ids
    if (keyName === "city_id") {
      const res = await attractionsNamesLookup({ city_id: [value?.id] });
      cityAttractionsLookup = res?.data?.data?.map(cityAttraction => {
        return {
          ...cityAttraction,
          label: cityAttraction?.name,
        }
      });
      attractions_ids = null;
    }
    attractionItem = { ...attractionItem, [keyName]: value, attractions_ids, cityAttractionsLookup }
    attractionsClone[attractionIndex] = attractionItem;
    setOperationStatement({ ...OperationStatement, attractions: attractionsClone });
    setErrors({
      ...errors,
      ...validate(
        { name: `${keyName}-${attractionIndex}`, value: attractionItem[keyName] },
        { required: true, }
      ),
    })
  }

  function deleteAtttraction(attractionIndex) {
    let attractionsClone = [...attractionsData];
    attractionsClone.splice(attractionIndex, 1);
    setOperationStatement({ ...OperationStatement, attractions: attractionsClone });
  }


  // const checkFormErrors = () => {
  //   const validationsKeys = ['attraction_id', 'city_id', 'date_time']
  //   let submitErrors = {}
  //   attractionsData?.forEach((attractionItem, index) => {
  //     validationsKeys?.forEach(key => {
  //       submitErrors = {
  //         ...submitErrors,
  //         ...validate(
  //           { name: `${key}-${index}`, value: attractionItem[key] },
  //           { required: true, }
  //         ),
  //       }
  //     })
  //   });
  //   setErrors({ ...submitErrors });
  // };

  // const saveProduct = async () => {
  //   checkFormErrors();
  //   if (!isErrorLoaded) {
  //     setIsErrorLoaded(true);
  //   } else {
  //     setIsErrorLoaded(false);
  //   }
  // };

  // async function handleAddEditAttractions() {
  //   const data = statementId ? {
  //     attractions: attractionsData?.map(item => {
  //       return {
  //         statementId,
  //         attraction_id: item?.attraction_id?.id,
  //         city_id: item?.city_id?.id,
  //         date_time: moment(item?.date_time).utc(true).toDate(),

  //       }
  //     })
  //   } : {
  //     attractions: attractionsData?.map(item => {
  //       return {
  //         attraction_id: item?.attraction_id?.id,
  //         city_id: item?.city_id?.id,
  //         date_time: moment(item?.date_time).toDate(),

  //       }
  //     })
  //   }
  //   const res = await addEditAttractions(statementId, data);
  //   if (res?.status >= 200 && res?.status < 300) {
  //     store.addNotification({
  //       title: `${interests.messages.updateSuccessfully}`,
  //       message: `${interests.messages.EntriesUpdateSuccess}`,
  //       type: "success",
  //       insert: "top",
  //       container: "top-right",
  //       animationIn: ["animated", "fadeIn"],
  //       animationOut: ["animated", "fadeOut"],
  //       dismiss: {
  //         duration: 3000,
  //         onScreen: true,
  //         pauseOnHover: true,
  //       },
  //     });
  //   }
  // }

  // useEffect(() => {
  //   if (isFormValid(errors) && isFirst) {
  //     handleAddEditAttractions();
  //   }
  // }, [isErrorLoaded])

  // useEffect(() => {
  //   if (activeTab === "operation-attraction") {
  //     saveProduct();
  //   }
  // }, [attractionsData]);
  return (
    <div>
      {attractionsData?.map((item, index) => {
        return (
          <div className="row mx-0 mt-3 flex-wrap align-items-center attraction-item" key={`attractions-${index}`}>
            <div className="col-md-3">
              <SelectField
                label={operationStatement.City}
                id="attraction-city"
                name="attraction-city"
                options={citiesLookup}
                value={item?.city_id?.name}
                onChange={(e) => {
                  handleAttractionsInputs(e, "city_id", index);
                }}
                color={errors?.[`city_id-${index}`]?.required ? "danger" : ""}
                errors={errors?.[`city_id-${index}`]}
                disabled={operationHasSent}
              />
            </div>
            <div className="col-md-3">
              <SelectField
                label={operationStatement.AttractionsName}
                placeholder={attractionsData?.[index]?.attractions_ids}
                class="fixH"
                id="attraction-name"
                name="attraction-name"
                options={item?.cityAttractionsLookup}
                defaultValue={attractionsData?.[index]?.attractions_ids}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(values) => handleAttractionsInputs(values || [], "attractions_ids", index)}
                multi
              />
            </div>
            <div className="col-md-3">
              <DateTimePickerField
                label={operationStatement.DateTime}
                date={item?.date_time}
                placeholder="DD/MM/YYYY"
                onChangeDate={(date) => {
                  handleAttractionsInputs(date, "date_time", index);
                }}
                minDate={moment(new Date()).add(1, "d")["_d"]}
                color={errors?.[`date_time-${index}`]?.required ? "danger" : ""}
                errors={errors?.[`date_time-${index}`]}
                timeInputLabel={"Attraction time"}
                disabled={operationHasSent}
              />
            </div>
            {!operationHasSent ? <button className='btn px-1 py-0 mt-4 font-16' onClick={() => deleteAtttraction(index)}>
              <DeleteIcon />
            </button>
              : null}
          </div>
        )
      })}
      {!operationHasSent ?
        <button className='btn-plus-operation px-0 mt-3'
          onClick={() => addNewAttraction()}
        >
          <span className='me-1 ms-0'><i class="fas fa-plus"></i></span>
          {operationStatement.AddAttraction}
        </button>
        : null
      }


      {/* {!operationHasSent ?
        <div className="w-100 mt-3 gap-1  d-flex justify-content-end">
          <button
            className="btn-Product-Filter bg-nxt justify-content-center"
            onClick={() => {
              saveProduct()
              // setSaveTabName("operation-attraction")
              setisFirst(true)
            }}
            disabled={attractionsData?.length === 0 || isDisabled}
          >
            {productsBuilder.Save}
          </button>
          {!isAuth &&
            <button
              className="btn-Product-Filter bg-nxt justify-content-center"
              onClick={() => {
                // setSaveTabName("operation-hotels")
                submitProduct();
                setisFirst(true);
              }}
              disabled={!operation_details?.statement_type?.id}
            >
              {productsBuilder.submit}
            </button>
          }
        </div>
        : null} */}
    </div>
  )
}
