import { roomTypesLookup } from 'modules/products-builder/Requestes/RequestView/helpers/localLookups';

export function formatRoomCount(rooms) {
  const roomCounts = rooms.reduce((acc, room) => {
      acc[room.room_type] = (acc[room.room_type] || 0) + 1;
      return acc;
  }, {});

  const roomLabels = roomTypesLookup

  return Object.entries(roomCounts)
      .map(([type, count]) => `${count} ${roomLabels[type] || "unknown"}`)
      .join(", ");
}