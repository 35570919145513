import React, { useEffect, useState } from 'react';
import { ReactComponent as DeleteIcon } from "assets/images/umrah-operations/trash-icon.svg";
import TextField from 'components/Form/TextField/TextField';
import validate, { isFormValid } from "helpers/validate";
import Locale from 'translations';
import { addEditOtherServices } from 'services/operationStatement';
import { useParams, useLocation } from 'react-router-dom';
import { store } from 'react-notifications-component';
import NumberField from 'components/Form/NumberField/NumberField';
import { useSBSState } from 'context/global';


export default function OperationStatementOtherServices({ operationHasSent, OperationStatement, setOperationStatement, isDisabled,
  submitProduct
  // errors,
  // setErrors,
  // activeTab,
  // setSaveTabName
}) {
  const otherServices = OperationStatement?.other_services;
  const { productsBuilder, interests, operationStatement } = Locale;
  const { isAuth } = useSBSState();
  const { reference, id } = useParams();
  const location = useLocation();
  const isPublic = location.pathname.includes("public");
  const statementId = isPublic ? id : reference;
  const operation_details = OperationStatement.operation_details;

  const [isFirst, setisFirst] = useState(false)
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  function addNewService() {
    const servicesClone = [...otherServices]
    const newServiceObj = {
      name: "",
      pax: "",
    };
    setOperationStatement({ ...OperationStatement, other_services: [...servicesClone, newServiceObj] });
  }

  function deleteOtherService(serviceIndex) {
    let servicesClone = [...otherServices];
    servicesClone.splice(serviceIndex, 1);
    setOperationStatement({ ...OperationStatement, other_services: servicesClone });
  }

  function handleServiceInputs(value, keyName, serviceIndex) {
    let servicesClone = [...otherServices];
    let serviceItem = servicesClone[serviceIndex];
    serviceItem = { ...serviceItem, [keyName]: value }
    servicesClone[serviceIndex] = serviceItem;
    setOperationStatement({ ...OperationStatement, other_services: servicesClone });
    setErrors({
      ...errors,
      ...validate(
        { name: `${keyName}-${serviceIndex}`, value: serviceItem[keyName] },
        {
          required: true,
          maxNumber: keyName === "pax" ? +OperationStatement?.adults : false,
        }
      ),
    })
  }

  const checkFormErrors = (services) => {
    const validationsKeys = ['name', 'pax'];
    let submitErrors = {}
    services?.forEach((service, index) => {
      validationsKeys?.forEach(key => {
        submitErrors = {
          ...submitErrors,
          ...validate(
            { name: `${key}-${index}`, value: service[key] },
            {
              required: true,
              maxNumber: key === "pax" ? +OperationStatement?.adults : false,
            }
          ),
        }
      })
    });
    setErrors({ ...submitErrors });
  };

  function saveServices() {
    checkFormErrors(otherServices);
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }

  async function handleAddEditOtherServices() {
    const data = {
      services: otherServices?.map(service => ({ name: service?.name, pax: +service?.pax }))
    }
    const res = await addEditOtherServices(statementId, data);
    if (res?.status >= 200 && res?.status < 300) {
      store.addNotification({
        title: `${interests.messages.updateSuccessfully}`,
        message: `${interests.messages.EntriesUpdateSuccess}`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  }


  useEffect(() => {
    if (isFormValid(errors) && isFirst) {
      handleAddEditOtherServices();
    }
  }, [isErrorLoaded])
  // useEffect(() => {
  // 	if (activeTab === "operation-other") {
  // 		saveServices();
  // 	}
  // }, [otherServices]);
  return (
    <div>
      {otherServices?.map((service, index) => {
        return (
          <div className="row mx-0 mt-3 flex-wrap attraction-item">
            <div className="col-md-8">
              <TextField
                label={operationStatement.Service}
                placeholder={operationStatement.TypeServicedetails}
                name="name"
                id="name"
                value={service.name}
                onChange={(e) => handleServiceInputs(e.target.value, "name", index)}
                color={errors?.[`name-${index}`]?.required ? "danger" : ""}
                errors={errors?.[`name-${index}`]}
              />
            </div>
            <div className="col-md-3">
              <NumberField
                type="number"
                label={operationStatement.Pax}
                placeholder={operationStatement.Pax}
                removeArrow={true}
                name="pax"
                id="pax"
                max={+OperationStatement?.adults}
                value={service.pax}
                onChange={(e) => handleServiceInputs(e.target.value, "pax", index)}
                color={errors?.[`pax-${index}`]?.maxNumber || errors?.[`pax-${index}`]?.required}
                errors={errors?.[`pax-${index}`]}
              />

            </div>
            <button className='btn px-1 py-0 mt-3 font-16 shadow-none' onClick={() => deleteOtherService(index)}>
              <DeleteIcon />
            </button>
          </div>
        )
      })}

      {!operationHasSent ?
        <button className='btn-plus-operation px-0 mt-3'
          onClick={() => addNewService()}
        >
          <span className='me-1 ms-0'><i class="fas fa-plus"></i></span>
          {operationStatement.AddService}
        </button>
        : null}

      <div className="w-100 mt-3 gap-1  d-flex justify-content-end">
        <button
          className="btn-Product-Filter bg-nxt justify-content-center"
          onClick={() => {
            saveServices()
            setisFirst(true)
            // setSaveTabName("operation-other")
          }}
          disabled={otherServices?.length === 0 || isDisabled}
        >
          {productsBuilder.Save}
        </button>
        {!isAuth &&
          <button
            className="btn-Product-Filter bg-nxt justify-content-center"
            onClick={() => {
              submitProduct();
              setisFirst(true);
            }}
            disabled={!operation_details?.statement_type?.id}
          >
            {productsBuilder.submit}
          </button>
        }
      </div>
    </div>
  )
}
