import moment from 'moment'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export default function RequestsTable({ requestsList }) {
  return (
    <div className='wp-table-wrapper'>
      <table>
        <thead>
          <tr>
            <th>Request ID</th>
            <th>Request By</th>
            <th>Service name</th>
            <th>Guests</th>
            <th>Request Date</th>
            {/* <th>Status</th> */}
            <th className='text-center'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            requestsList.map((request) => {
              const date = request?.created_at
                ? request?.created_at.split(" ")[0]
                  ? moment(request?.created_at.split(" ")[0]).format("DD MMM YYYY")
                  : "-"
                : "-"

              const time = request?.created_at
                ? request?.created_at.split(" ")[1]
                  ? moment(request?.created_at.split(" ")[1], "HH:mm:ss").format("hh:mm A")
                  : "-"
                : "-"

              return (
                <tr>
                  <td>
                    <div className='wp-table-request-id'>#{request?.id}</div>
                  </td>
                  <td>{request?.client_name || "-"}</td>
                  <td>
                    <div className='wp-table-service-name'>
                      <span>{request?.name || "-"}</span>
                      <span>{request?.type || "-"}</span>
                    </div>
                  </td>
                  <td>2</td>
                  <td>
                    <div className='wp-table-request-date'>
                      <span>{date}</span>
                      <span>{time}</span>
                    </div>
                  </td>
                  {/* <td>
												<div className={`wp-reqquest-status ${STATUS["accepted"].className}`}>
													{STATUS["accepted"].text}
												</div>
											</td> */}
                  <td>
                    <Link to={`/web-pages-requests/${request?.id}`}>
                      <button className='wp-view-request-btn'>&gt;</button>
                    </Link>
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}