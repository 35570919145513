import { useCallback, useEffect, useState } from "react";
import { STATUS } from ".";
import {
	DangerIcon,
	InboundIcon,
	OutboundIcon,
	RefreshIcon,
	RepeatIcon,
} from "./icons";
import { fetchBuilderRequestById, addCallLog } from 'services/webBuilder';
import { useParams } from 'react-router-dom';
import { useSBSState } from 'context/global';
import moment from 'moment';
import { formatRoomCount } from './utils';

const TABS = [
	{
		title: "Call Logs",
		Component: CallLogs,
	},
	// {
	// 	title: "History",
	// 	Component: History,
	// },
];

export default function ViewWebRequests() {
	const { locale } = useSBSState()
	const { id } = useParams()
	const [activeTabIndex, setActiveTabIndex] = useState(0); // 0: Call Logs, 1: History
	const TabContentComponent = TABS[activeTabIndex].Component;

	const [requestData, setRequestData] = useState(null)

	const [callLogDetails, setCallLogDetails] = useState({
		service_request_id: +id,
		note: "",
		type: "", // inbound, outbound
	})

	let pax = ""

	if (requestData?.request?.adults_count) {
		pax += requestData?.request?.adults_count + " adults"
	} else if (requestData?.request?.children_count) {
		pax += "- " + requestData?.request?.children_count + " children"
	} else if (requestData?.request?.infants_count) {
		pax += "- " + requestData?.request?.infants_count + " infants"
	}

	let duration = null

	if (requestData?.request?.date_from && requestData?.request?.date_to) {
		const dateFromMomentObj = moment(requestData?.request?.date_from)
		const dateToMomentObj = moment(requestData?.request?.date_to)

		const _duration = moment.duration(dateToMomentObj.diff(dateFromMomentObj));

		let nightsCount = 0;

		if (typeof requestData?.service?.nights_count === "number") {
			nightsCount = requestData?.service?.nights_count + " Nights";
		} else {
			nightsCount = "-"
		}

		duration = `${_duration.asDays()} Day / ${nightsCount}`
	}

	let destination = null

	if (requestData?.request?.destination_from && requestData?.request?.destination_from) {
		destination = `${requestData?.request?.destination_from}, ${requestData?.request?.destination_to}`
	}

	let inclusion = null

	if (requestData?.service?.inclusion) {
		inclusion = requestData?.service?.inclusion.join(" & ")
	}

	const getRequestData = useCallback(async () => {
		const res = await fetchBuilderRequestById(id)
		if (res.status === 200) {
			setRequestData(res.data.data)
		}
	}, [id])

	useEffect(() => {
		getRequestData()

	}, [getRequestData])

	return (
		<div className="web-pages-requests-wrapper-view">
			<div className="wp-view-card">
				{/* header */}
				<div className="wp-view-header">
					<h2>{requestData?.service?.name[locale] || requestData?.service?.name?.en || "-"}</h2>

					{/* <div className={`wp-reqquest-status ${STATUS["new"].className}`}>
						{STATUS["new"].text}
					</div> */}
				</div>

				{/* info container */}
				<div className="wp-view-info-container mb-3">
					<h3 className="wp-view-info-container-title">Client Details</h3>

					{/* Info Box */}
					<div className="wp-view-info-container-content grid">
						<div className="wp-view-info-box">
							<div className="wp-view-info-box-title">Client Name</div>
							<div className="wp-view-info-box-content">{requestData?.client?.name || "-"}</div>
						</div>

						<div className="wp-view-info-box">
							<div className="wp-view-info-box-title">Email</div>
							<div className="wp-view-info-box-content">{requestData?.client?.email || "-"}</div>
						</div>

						<div className="wp-view-info-box">
							<div className="wp-view-info-box-title">Phone Number</div>
							<div className="wp-view-info-box-content">{requestData?.client?.phone || "-"}</div>
						</div>

						<div className="wp-view-info-box">
							<div className="wp-view-info-box-title">What's App Number</div>
							<div className="wp-view-info-box-content">{requestData?.client?.whatsapp_number || "-"}</div>
						</div>
					</div>
				</div>

				{/* info container */}
				<div className="wp-view-info-container mb-3">
					<h3 className="wp-view-info-container-title">Request Details</h3>

					{/* Info Box */}
					<div className="wp-view-info-container-content grid">
						<div className="wp-view-info-box">
							<div className="wp-view-info-box-title">Service Type</div>
							<div className="wp-view-info-box-content">{requestData?.type || "-"}</div>
						</div>

						<div className="wp-view-info-box">
							<div className="wp-view-info-box-title">Class</div>
							<div className="wp-view-info-box-content">{requestData?.request?.cabin_class || "-"}</div>
						</div>

						<div className="wp-view-info-box">
							<div className="wp-view-info-box-title">Destination</div>
							<div className="wp-view-info-box-content">{destination || "-"}</div>
						</div>

						<div className="wp-view-info-box">
							<div className="wp-view-info-box-title">Inclusion</div>
							<div className="wp-view-info-box-content">{inclusion || "-"}</div>
						</div>

						<div className="wp-view-info-box">
							<div className="wp-view-info-box-title">Rooms</div>
							<div className="wp-view-info-box-content">{requestData?.request?.rooms ? formatRoomCount(requestData?.request?.rooms) : "-"}</div>
						</div>

						<div className="wp-view-info-box">
							<div className="wp-view-info-box-title">Pax</div>
							<div className="wp-view-info-box-content">{pax || "-"}</div>
						</div>

						<div className="wp-view-info-box">
							<div className="wp-view-info-box-title">Duration</div>
							<div className="wp-view-info-box-content">{duration || "-"}</div>
						</div>

						<div className="wp-view-info-box">
							<div className="wp-view-info-box-title">Check-In</div>
							<div className="wp-view-info-box-content">{requestData?.request?.date_from ? moment(requestData?.request?.date_from).format("DD/MM/YYYY") : "-"}</div>
						</div>

						<div className="wp-view-info-box">
							<div className="wp-view-info-box-title">Check-Out</div>
							<div className="wp-view-info-box-content">{requestData?.request?.date_from ? moment(requestData?.request?.date_to).format("DD/MM/YYYY") : "-"}</div>
						</div>
					</div>
				</div>

				{/* info container */}
				<div className="wp-view-info-container">
					<h3 className="wp-view-info-container-title">Notes</h3>

					{/* Info Box */}
					<div className="wp-view-info-container-content">
						<div className="wp-view-info-box">
							<p>
								{requestData?.notes || "-"}
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="wp-view-card right">
				<div className="wp-tabs-wrapper">
					<ul className="wp-tabs">
						{TABS.map((tab, index) => (
							<li key={index}>
								<button
									className={`wp-tab ${activeTabIndex === index ? "active" : ""
										}`}
									onClick={() => setActiveTabIndex(index)}
								>
									{tab.title}
								</button>
							</li>
						))}
					</ul>
				</div>

				<div className="wp-tabs-content">
					<TabContentComponent
						callLogDetails={callLogDetails}
						setCallLogDetails={setCallLogDetails}
						requestData={requestData}
						getRequestData={getRequestData}
					/>
				</div>
			</div>
		</div>
	);
}

function CallLogs({ callLogDetails, setCallLogDetails, requestData, getRequestData }) {
	return (
		<div className="wp-call-logs">
			{/* call buttons */}
			<div className="wp-call-btn-group">
				<button className="wp-view-btn wp-view-inbound-btn" onClick={() => setCallLogDetails({ ...callLogDetails, type: "inbound" })}>
					<InboundIcon />
					<span>Inbound</span>
				</button>

				<button className="wp-view-btn wp-view-outbound-btn" onClick={() => setCallLogDetails({ ...callLogDetails, type: "outbound" })}>
					<OutboundIcon />
					<span>Outbound</span>
				</button>
			</div>

			{/* Note */}
			<div className="wp-note">
				<label htmlFor="note">Note</label>
				<textarea
					id="note"
					placeholder="Add notes about this call..."
					rows="4"
					value={callLogDetails.note}
					onChange={(e) => setCallLogDetails({ ...callLogDetails, note: e.target.value })}
				/>
			</div>

			{/* Save Button */}
			<button
				className="wp-save-btn"
				onClick={async () => {
					setCallLogDetails({...callLogDetails, note: ""})
					await addCallLog(callLogDetails)
					getRequestData()
				}}
			>
				Save
			</button>

			{/* divider */}
			<hr className="wp-divider" />

			{/* Call Logs */}
			<div className="wp-call-logs">
				<h3 className="wp-call-logs-title">Call Logs</h3>

				<ul className="wp-call-logs-list">
					{requestData?.call_logs?.map((log, index) => (
						<li className="wp-call-log-item">
							<div>
								{log?.type === "inbound" ? <InboundIcon color="green" /> : <OutboundIcon />}
							</div>
							<div className="wp-call-log-item-content">
								<div className="wp-call-log-item-header">
									<span className="wp-call-log-item-header-name">
										Logged By {log.user}
									</span>
									<span className="wp-call-log-item-header-date">
										{moment(log.created_at).format("DD.MMM.YYYY")} | {moment(log.created_at).format("hh:mm")}
									</span>
								</div>
								<div className="wp-call-log-item-note">
									{log.note || "-"}
								</div>
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

function History() {
	return (
		<ul className="wp-history-list">
			<li className="wp-history-list-item">
				<div>
					<RefreshIcon />
				</div>
				<div className="wp-history-item-content">
					<div className="wp-history-item-header">
						<span className="wp-history-item-title">Change Package</span>
						<span className="wp-history-item-date">10.Dec.2023 | 20:00</span>
					</div>

					<div className="wp-history-item-note">
						Hesham changed package to Umrah 2
					</div>
				</div>
			</li>

			<li className="wp-history-list-item">
				<div>
					<RepeatIcon />
				</div>
				<div className="wp-history-item-content">
					<div className="wp-history-item-header">
						<span className="wp-history-item-title">Change Package</span>
						<span className="wp-history-item-date">10.Dec.2023 | 20:00</span>
					</div>

					<div className="wp-history-item-note">
						Hesham changed package to Umrah 2
					</div>
				</div>
			</li>

			<li className="wp-history-list-item">
				<div>
					<DangerIcon />
				</div>
				<div className="wp-history-item-content">
					<div className="wp-history-item-header">
						<span className="wp-history-item-title">Change Package</span>
						<span className="wp-history-item-date">10.Dec.2023 | 20:00</span>
					</div>

					<div className="wp-history-item-note">
						Hesham changes priority to <span className='wp-priority high'>High</span>
					</div>
				</div>
			</li>
		</ul>
	);
}
