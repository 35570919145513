import React, { useEffect, useState } from 'react'
import TransportationMovments from './Movments'
import TransportationVehicles from './Vehicles'
import SelectField from 'components/Form/SelectField/SelectField'
import TextField from 'components/Form/TextField/TextField';
import { fetchVehicleTypes } from 'services/lookups';
import { fetchTransporters } from 'services/lookups';
import { useSBSState } from 'context/global';
// import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import Locale from 'translations';
import validate, { isFormValid } from "helpers/validate";
import moment from 'moment';
import { addEditTransportation, generateMovements } from 'services/operationStatement';
import { useParams, useLocation } from 'react-router-dom';
import { store } from 'react-notifications-component';
import { getOperationsLookup } from 'modules/UmrahOperations/helper/operationsLookup';
import OperationStatementAttractions from '../Attractions';




export default function OperationStatementTransportation({ OperationStatement, setOperationStatement, isDisabled, operationHasSent, submitProduct }) {
  const transportationData = OperationStatement?.transportation;
  const vehiclesList = transportationData?.vehicles;
  const movementsList = transportationData?.movements;
  const { locale, isAuth } = useSBSState();
  const { productsBuilder, interests, operationStatement } = Locale;
  const { reference, id } = useParams();
  const location = useLocation();
  const isPublic = location.pathname.includes("public");
  const statementId = isPublic ? id : reference;
  const operation_details = OperationStatement.operation_details;
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const [vehiclesTypesLookups, setVehiclesTypesLookups] = useState([]);
  const [transportersList, setTransportersList] = useState([]);
  const [isFirst, setisFirst] = useState(false);
  const validationsKeys = ['cycle_type', 'request_type', 'transporter_id'];

  const { cycleTypesLookup, requestTypeLookup, citiesLookup, movmentTypesLookup } = getOperationsLookup(Locale);


  // changes in transportation details inputs
  function handleTransportationDetailsInputs(value, keyName, eventType) {
    if (keyName === "transporter_id" && eventType === "change") {
      value = { name: value }
    }
    let transportationClone = { ...transportationData, [keyName]: value };
    setOperationStatement({ ...OperationStatement, transportation: transportationClone });
    // handle errors on change input
    if (validationsKeys.includes(keyName) && eventType !== "change") {
      setErrors({
        ...errors,
        ...validate(
          {
            name: keyName,
            value: keyName === "transporter_id" ? transportationClone[keyName]?.name : transportationClone[keyName]
          },
          { required: true, }
        ),
      })
    }
  };

  // add new vehicle row
  function addNewVehicle() {
    const vehiclesCLone = [...vehiclesList];
    const newVehicleObj = {
      vehicle_type_id: null,
      vehicle_model: null,
      count: "",
    };
    setOperationStatement({
      ...OperationStatement,
      transportation: {
        ...transportationData,
        vehicles: [...vehiclesCLone, newVehicleObj]
      }
    });
  };

  // delete vehicle
  function deleteVehicle(vehicleIndex) {
    const vehiclesCLone = [...vehiclesList];
    vehiclesCLone.splice(vehicleIndex, 1);
    setOperationStatement({
      ...OperationStatement,
      transportation: {
        ...transportationData,
        vehicles: [...vehiclesCLone]
      }
    });
  };

  // changes in vehicles inouts
  function handleVehiclesInputs(value, keyName, index) {
    let vehiclesCLone = [...vehiclesList];
    let vehicleItem = { ...vehiclesCLone[index], [keyName]: value }
    vehiclesCLone[index] = vehicleItem;
    setOperationStatement({
      ...OperationStatement,
      transportation: {
        ...transportationData,
        vehicles: [...vehiclesCLone]
      }
    });
    setErrors({
      ...errors,
      ...validate(
        { name: `${keyName}-${index}`, value },
        { required: true }
      ),
    })
  };

  // add movment row
  function addNewMovment() {
    const movmentsCLone = [...movementsList];
    const newMovmentObj = {
      movement_date: null,
      movement_type: null,
      pickup_point: "",
      destinations: [{ label: null, value: null }],
      pickup_points: [{ label: null, value: null }],
      pickup_time: null,
    };
    setOperationStatement({
      ...OperationStatement,
      transportation: {
        ...transportationData,
        movements: [...movmentsCLone, newMovmentObj]
      }
    });
  };

  // delete movment
  function deleteMovment(movementIndex) {
    const movmentsCLone = [...movementsList];
    movmentsCLone.splice(movementIndex, 1);
    setOperationStatement({
      ...OperationStatement,
      transportation: {
        ...transportationData,
        movements: [...movmentsCLone]
      }
    });
  };

  // changes in movments inouts
  function handleMovmentsInputs(value, keyName, index) {
    let movmentsCLone = [...movementsList];
    let movmentItem = { ...movmentsCLone[index], [keyName]: value }
    movmentsCLone[index] = movmentItem;
    setOperationStatement({
      ...OperationStatement,
      transportation: {
        ...transportationData,
        movements: [...movmentsCLone]
      }
    });
    setErrors({
      ...errors,
      ...validate(
        { name: `${keyName}-${index}`, value },
        { required: true }
      ),
    })
  };

  const handlePickupsInputs = (value, keyName, index, movmentIndex) => {
    let movmentsCLone = [...movementsList];
    let movmentItem = { ...movmentsCLone[index] }
    movmentItem[keyName][movmentIndex] = value
    movmentsCLone[index] = movmentItem;
    setOperationStatement({
      ...OperationStatement,
      transportation: {
        ...transportationData,
        movements: [...movmentsCLone]
      }
    });
  }

  const checkFormErrors = () => {
    let submitErrors = {}
    validationsKeys?.forEach(key => {
      submitErrors = {
        ...submitErrors,
        ...validate(
          {
            name: key,
            value: key === "transporter_id" ? transportationData[key]?.name : transportationData[key]
          },
          { required: true }
        ),
      }
    });
    vehiclesList?.forEach((vehicle, vehicleIndex) => {
      Object.keys(vehicle).forEach(key => {
        submitErrors = {
          ...submitErrors,
          ...validate(
            { name: `${key}-${vehicleIndex}`, value: vehicle[key] },
            { required: true }
          ),
        }
      })
    })
    // movementsList?.forEach((movement, movementIndex) => {
    //   Object.keys(movement).forEach(key => {
    //     submitErrors = {
    //       ...submitErrors,
    //       ...validate(
    //         { name: `${key}-${movementIndex}`, value: movement[key] },
    //         { required: true }
    //       ),
    //     }
    //   })
    // })
    setErrors({ ...submitErrors });
  };

  // get tranpostations list
  const getTransportersList = async (inputValue) => {
    // if (inputValue.length > 2) {
    const transportersRes = await fetchTransporters(1, "", "966");
    let result = []
    transportersRes?.forEach(item => {
      result.push({
        ...item,
        name: item.name?.[locale] || item.name,
        value: item.id,
        label: item.name?.[locale] || item.name,
      })
    });
    setTransportersList(result);
    // }
  };

  // get tranpostations list
  const generateTransportersList = async () => {

    const transportersRes = await generateMovements(statementId);

    setOperationStatement({
      ...OperationStatement,
      transportation: {
        ...transportationData,
        movements:
          transportersRes && transportersRes.length > 0
            ? transportersRes.map((movement) => {

              return {
                pickup_city_id: citiesLookup?.find(type => type?.id === movement?.pickup_city),
                destination_city_id: citiesLookup?.find(type => type?.id === movement?.drop_off_city),
                movement_type: movmentTypesLookup?.find(
                  (type) => type?.id === movement?.type
                ),
                movement_date: movement?.date
                  ? moment(movement?.date).toDate()
                  : null,
                pax: movement.pax,
                pickup_points: movement?.from && movement?.from.length > 0 ? movement?.from.map(res => {
                  return {
                    ...res,
                    label: res.title,
                    id: res.item_id,
                    value: res.item_id,
                    name: res.title,
                  }
                }) : [],
                destinations: movement?.to && movement?.to.length > 0 ? movement?.to.map(res => {
                  return {
                    ...res,
                    label: res.title,
                    id: res.item_id,
                    value: res.item_id,
                    name: res.title,
                  }
                }) : [],
                pickup_time: movement?.date
                  ? moment(movement?.date)
                  : null,
              };
            })
            : [],
      },
    });
  };


  const saveTransportation = async () => {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  };

  async function handleAddEditTransportation() {
    const data = {
      transporter_id: transportationData?.transporter_id?.id,
      cycle_type: transportationData?.cycle_type?.id,
      request_type: transportationData?.request_type?.id,
      credential_number: transportationData?.credential_number,
      operation_id: transportationData?.operation_id,

      vehicles: transportationData?.vehicles?.map(vehicle => {
        return {
          vehicle_type_id: vehicle?.vehicle_type_id?.id,
          vehicle_model: vehicle?.vehicle_model?.id,
          count: +vehicle?.count,
        }
      }),
      movements: transportationData?.movements?.map(movement => {
        return {
          movement_type: movement?.movement_type?.id,
          movement_date: movement?.movement_date ? movement?.movement_date : null,
          pickup_points: movement?.pickup_points,
          destinations: movement?.destinations,
          pax: +movement?.pax,
          pickup_city_id: movement?.pickup_city_id?.id,
          destination_city_id: movement?.destination_city_id?.id,
          pickup_time: movement?.pickup_time ? moment(movement?.pickup_time).format("hh:mm") : null
        }
      }),
    }
    const res = await addEditTransportation(statementId, data);
    if (res?.status >= 200 && res?.status < 300) {
      store.addNotification({
        title: `${interests.messages.updateSuccessfully}`,
        message: `${interests.messages.EntriesUpdateSuccess}`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  }

  useEffect(() => {
    async function vehicleTypesLookups() {
      const res = await fetchVehicleTypes();
      setVehiclesTypesLookups(res);
    }
    vehicleTypesLookups();
    getTransportersList();
  }, []);

  useEffect(() => {
    if (isFormValid(errors) && isFirst) {
      handleAddEditTransportation();
    }
  }, [isErrorLoaded])

  // useEffect(() => {
  // 	if (activeTab === "operation-transportation") {
  // 		saveTransportation();
  // 	}
  // }, [transportationData]);
  return (
    <div className='operations-transportations'>
      <div className="row mx-0">
        {/* Cycle Type */}
        <div className="col-md-3 col-12">
          <SelectField
            label={operationStatement.CycleType}
            placeholder={operationStatement.Select}
            id="cycle_type"
            name="cycle_type"
            options={cycleTypesLookup}
            value={transportationData?.cycle_type?.name}
            onChange={(e) => handleTransportationDetailsInputs(e, "cycle_type")}
            color={errors?.cycle_type?.required ? "danger" : ""}
            errors={errors?.cycle_type}
            disabled={operationHasSent}
          />
        </div>
        {/* Request Type */}
        <div className="col-md-3 col-12">
          <SelectField
            label={operationStatement.RequestType}
            placeholder={operationStatement.Select}
            id="request_type"
            name="request_type"
            options={requestTypeLookup}
            value={transportationData?.request_type?.name}
            onChange={(e) => handleTransportationDetailsInputs(e, "request_type")}
            color={errors?.request_type?.required ? "danger" : ""}
            errors={errors?.request_type}
            disabled={operationHasSent}

          />
        </div>
      </div>
      {/* Transporter Name */}
      <div className="row mx-0 mt-2">
        <div className="col-md-3 col-12">
          <SelectField
            label={operationStatement.TransporterName}
            placeholder={operationStatement.Select}
            id="transporter_id"
            name="transporter_id"
            options={transportersList}
            value={transportationData?.transporter_id?.name || ""}
            onChange={(e) => handleTransportationDetailsInputs(e, "transporter_id", "selectValue")}
            color={errors?.transporter_id?.required ? "danger" : ""}
            errors={errors?.transporter_id}
            disabled={operationHasSent}

          />
          {/* <AutoCompleteField
            label={operationStatement.TransporterName}
            id="transporter_id"
            name="transporter_id"
            placeholder={operationStatement.Select}
            isSearchable={true}
            listAuto={transportersList}
            setListAuto={setTransportersList}
            getListAuto={getTransportersList}
            value={transportationData?.transporter_id?.name || ""}
            onChange={(e) => {
              handleTransportationDetailsInputs(e, "transporter_id", "change");
            }}
            onSelectValue={(e) => {
              handleTransportationDetailsInputs(e, "transporter_id", "selectValue")
            }}
            color={errors?.transporter_id?.required ? "danger" : ""}
            errors={errors?.transporter_id}
            disabled={operationHasSent}

          /> */}
        </div>
        {/* Credential No */}
        <div className="col-md-3 col-12">
          <TextField
            label={operationStatement.CredentialNo}
            placeholder={operationStatement.TypeCredentialNo}
            id="credential_number"
            name="credential_number"
            value={transportationData?.credential_number}
            onChange={(e) => handleTransportationDetailsInputs(e.target.value, "credential_number")}
            disabled={operationHasSent}

          />
        </div>
        {/* Operation ID */}
        <div className="col-md-3 col-12">
          <TextField
            label={operationStatement.OperationID}
            placeholder={operationStatement.TypeOperationID}
            id="operation_id"
            name="operation_id"
            value={transportationData?.operation_id}
            onChange={(e) => handleTransportationDetailsInputs(e.target.value, "operation_id")}
            disabled={operationHasSent}

          />
        </div>
      </div>

      {/* full-cycle-with-attractions */}
      {transportationData?.cycle_type?.id === "full-cycle-with-attractions" ?
        <div className="col-12">
          <div className="mt-3 py-3 px-2 border border-gray" style={{ borderRadius: "8px" }}>
            <h4 className="font-weight-bold col-12 h5">{operationStatement.attractions} </h4>
            <OperationStatementAttractions
              operationHasSent={operationHasSent}
              OperationStatement={OperationStatement}
              setOperationStatement={setOperationStatement}
              isDisabled={isDisabled}
              submitProduct={submitProduct}
            />
          </div>
        </div>
        :
        null
      }

      <div className='px-1 mt-3'>
        <TransportationVehicles
          vehiclesList={vehiclesList}
          vehiclesTypesLookups={vehiclesTypesLookups}
          addNewVehicle={addNewVehicle}
          deleteVehicle={deleteVehicle}
          handleVehiclesInputs={handleVehiclesInputs}
          errors={errors}
          disabled={operationHasSent}
          operationHasSent={operationHasSent}

        />
      </div>

      <div className='px-1 mt-3'>
        <TransportationMovments
          generateTransportersList={generateTransportersList}
          handlePickupsInputs={handlePickupsInputs}
          movementsList={movementsList}
          addNewMovment={addNewMovment}
          deleteMovment={deleteMovment}
          handleMovmentsInputs={handleMovmentsInputs}
          errors={errors}
          operationHasSent={operationHasSent}
        />
      </div>



      {!operationHasSent ?
        <div className="w-100 mt-3 gap-1  d-flex justify-content-end">
          <button
            className="btn-Product-Filter bg-nxt justify-content-center"
            onClick={() => {
              saveTransportation()
              // setSaveTabName("operation-transportation")
              setisFirst(true)
            }}
            disabled={isDisabled}
          >
            {productsBuilder.Save}
          </button>
          {!isAuth &&
            <button
              className="btn-Product-Filter bg-nxt justify-content-center"
              onClick={() => {
                submitProduct();
                setisFirst(true);
              }}
              disabled={!operation_details?.statement_type?.id}            >
              {productsBuilder.submit}
            </button>
          }
        </div>
        : null}
    </div>
  )
}
