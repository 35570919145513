import TextField from 'components/Form/TextField/TextField';
import SearchIcon from 'assets/images/crm/search.svg'
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import { useState } from 'react';
import { serviceBuilderPackagesList } from 'services/webBuilder';
import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import ClearIcon from 'modules/products-builder/Pricing/IconsSVG/ClearIcon'


export default function ServiceFilter({ filters, handleFilterChange, fetchList, clearFilter }) {
  const [listAuto, setListAuto] = useState([]);

  const getListAuto = async (inputValue) => {
    if (inputValue.length > 2) {
      const packages = await serviceBuilderPackagesList({ name: inputValue });

      const result = packages.data.data.map((item) => ({
        // for auto complete field
        id: item.id,
        label: item.name,
        name: item.name,
        value: item.name,
      }));

      setListAuto(result);
    }
  };

  return (
    <div className='wp-filter'>
      {/* Search */}
      <div>
        <TextField
          type='text'
          id='search'
          name='search'
          placeholder={"Search..."}
          isImage={true}
          image={SearchIcon}
          prependImage={true}
          onChange={(e) => handleFilterChange("client_name", e.target.value)}
          value={filters.client_name}
          hasLabel={false}
        />
      </div>

      {/* Package Name Request Data */}
      <div>
        {/* Package Name */}
        <div>
          <AutoCompleteField
            hasLabel={false}
            listAuto={listAuto}
            setListAuto={setListAuto}
            getListAuto={getListAuto}
            isSearchable={true}
            placeholder={"Package Name"}
            value={filters?.package?.name}
            onChange={(e) => handleFilterChange("package", e)}
            onSelectValue={(e) => {
              handleFilterChange("package", e);
            }}
          />
        </div>
        {/*  Request Date */}
        <div>
          <DatePickerField
            id="request_date"
            name="request_date"
            placeholder={"Request Date"}
            date={filters.created_at}
            onChangeDate={(date) => {
              handleFilterChange("created_at", date)
            }}
            // select all dates
            isOutsideRange={() => false}
            hasLabel={false}
          />
        </div>
      </div>

      {/* Actions */}
      <div className='wp-actions'>
        <button
          className='wp-apply-filter-btn'
          onClick={
            () => {
              const { package: selectedPackage, rest } = filters
              fetchList({
                ...rest,
                created_at: filters.created_at?.format("YYYY-MM-DD"),
                service_id: selectedPackage?.id
              })
            }
          }
        >
          Apply Filter
        </button>
        <button className='wp-clear-filter-btn' onClick={clearFilter}>
          <ClearIcon />
          <span>Clear</span>
        </button>
      </div>
    </div>
  )
}