import { ExportIcon } from './icons'
import Pagination from 'components/Pagination'
import { useEffect, useState } from 'react'
import { exportBuilderRequestsExcel, fetchBuilderRequestsList } from 'services/webBuilder'
import RequestsTable from './RequestsTable'
import ServiceFilter from './ServiceFilter'

export const STATUS = {
	accepted: {
		className: 'wp-reqquest-status-accepted',
		text: 'Accepted'
	},
	pending: {
		className: 'wp-reqquest-status-pending',
		text: 'Pending'
	},
	new: {
		className: 'wp-reqquest-status-new',
		text: 'New'
	},
	cancelled: {
		className: 'wp-reqquest-status-cancelled',
		text: 'Cancelled'
	},
}

const SERVICES_TYPES = [
	{ text: "Hotels", value: "builder_request_hotel" },
	{ text: "Transfers", value: "builder_request_transfer" },
	{ text: "Flights", value: "builder_request_flight" },
	{ text: "Packages", value: "builder_request_package" },
	{ text: "Tours", value: "builder_request_tour" },
	{ text: "Others Services", value: "builder_request_other" },
]

const INITIAL_FILTERS = {
	client_name: "",
	created_at: null,
	package: "",
	page: 1,
}

function WebPageRequests() {
	const [requestsList, setRequestsList] = useState([])
	const [meta, setMeta] = useState(null)
	const [filters, setFilters] = useState(INITIAL_FILTERS)
	const [activeServiceType, setActiveServiceType] = useState(-1)

	const fetchList = async (filters) => {
		const res = await fetchBuilderRequestsList(filters)
		if (res.status === 200) {
			setRequestsList(res.data?.data)
			setMeta(res.data?.meta)
		}
	}

	const handleFilterChange = (key, value) => {
		const updatedFilters = { ...filters, [key]: value }
		setFilters(updatedFilters)
	}

	const clearFilter = () => {
		setFilters(INITIAL_FILTERS)
		fetchList(INITIAL_FILTERS)
	}

	const goTo = (page) => {
		setFilters({ ...filters, page })
		fetchList({ ...filters, page })
	}

	const handleExportExcel = async () => {
		const res = await exportBuilderRequestsExcel(filters);
		if (res.status === 200) {
			const url = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "requests.xlsx");
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};


	useEffect(() => {
		fetchList(INITIAL_FILTERS)
	}, [])

	return (
		<>
			<div className='web-pages-requests-wrapper'>
				{/* Header */}
				<div className='wp-header'>
					<h2>Requests</h2>
					<button onClick={handleExportExcel}>
						<ExportIcon />
						<span>Export to Excel</span>
					</button>
				</div>

				{/* Filter */}
				<ServiceFilter
					filters={filters}
					handleFilterChange={handleFilterChange}
					fetchList={fetchList}
					clearFilter={clearFilter}
				/>

				{/* Service Filter Tags */}
				<div>
					<ul className='wp-service-filter-tags'>
						<li>
							<button
								className={activeServiceType === -1 ? "active" : ""}
								onClick={() => {
									setActiveServiceType(-1)
									fetchList(filters)
								}}
							>
								All
							</button>
						</li>
						{
							SERVICES_TYPES.map((type, index) => (
								<li>
									<button
										className={activeServiceType === index ? "active" : ""}
										onClick={() => {
											setActiveServiceType(index)
											fetchList({ ...filters, type: type.value })
										}}
									>
										{type.text}
									</button>
								</li>
							))
						}
					</ul>
				</div>

				{/* Table */}
				<RequestsTable requestsList={requestsList} />
			</div>

			<div className='mt-3'>
				<Pagination info={meta} goTo={goTo} />
			</div>
		</>
	)
}



export default WebPageRequests
